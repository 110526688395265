#permissions {
  .checkbox-style {
    .form-check-input[disabled] ~ .form-check-label,
    .form-check-input:disabled ~ .form-check-label {
      opacity: 0.1;
    }
  }

  #dropdown-choose-method,
  #dropdown-check-method {
    font-size: 12px;
    font-weight: 600;
  }

  .method-hover:hover {
    background-color: var(--color-off-white);
    border-radius: 4px;
  }

  #form-choose-method {
    label.form-check-label {
      margin-left: 5px;
    }
  }

  @media (min-height: 500px) {
    .custom-btn-role {
      position: fixed;
      bottom: 0;
    }
  }

  .permissions-tab {
    > .row.permissions-panel {
      height: calc(100vh - (1rem + 1rem + 52px + 0.5rem) - 67.5px);

      .row.permissions-panel-header {
        height: 6rem;
      }

      .permissions-list {
        max-height: calc(100% - 6rem);
      }
    }
  }

  .role-selecter {
    color: var(--color-black);

    &.active {
      color: var(--color-white);
      background-color: var(--color-blue);
    }
  }

  .min-h-100vh {
    min-height: calc(100vh - (67.5px + 75px + 1rem + 1px));
  }
}
